import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function CampaignsEdit() {
  const navigate = useNavigate();
  const [target, setTarget] = useState("");
  const { id } = useParams();
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    client: "",
    budget: "",
    kickback: "",
    margin: "",
    kpi: "",
    kpi_cost: "",
    source: "",
    country: "",
    brand:"",
    template:"",
    report_type:"",
    output_filename:"",
    status: "",
    prompt:""
  });
  const toaster = useToast();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        setLoader();
        const response = await API.get(`/campaign/${id}`);
        unsetLoader();
        const data = response.data.data;
        setFormData(data);
        setTarget(response.data.data.name);
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
    };
    fetchCampaignData();
  }, [id]);

  // Handler for form submission
  const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        for (const key in formData) {
          if (!formData[key]) {
            toaster(
              {
                text: `${key} can't be empty!`,
              },
              { type: "error" },
            );
            return false;
          }
        }
  
        const numericFields = ["budget","kickback", "margin", "kpi_cost"];
        for (const field of numericFields) {
          const value = formData[field];
          console.log(value);
          if (isNaN(value)) {
            toaster(
              {
                text: `${field} must be a valid number!`,
              },
              { type: "error" },
            );
            return false;
          }
        }
        setLoader();
        console.log(formData);
        const response = await API.put(`/campaign`, formData);
        console.log(response.data); // Handle success
        if (response.data.message == "ok") {
          unsetLoader();
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "success" },
          );
          setTimeout(() => {
            navigate("/campaigns");
          }, 1000)
        } else {
          unsetLoader();
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" },
          );
        }
      } catch (error) {
        unsetLoader();
        toaster(
          { text: `An error occurred: ${error}` },
          { type: "error" },
        );
      }
    };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Edit Campaign - {target}</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client"
                  name="client"
                  value={formData.client}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Brand"
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                fullWidth
                label="Budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Royalties"
                  name="kickback"
                  value={formData.kickback}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Margin"
                  name="margin"
                  value={formData.margin}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="kpi">KPI:</InputLabel>
                  <Select
                    labelId="kpi"
                    name="kpi"
                    value={formData.kpi}
                    onChange={handleChange}
                  >
                    <MenuItem value="CPC">CPC</MenuItem>
                    <MenuItem value="CPM">CPM</MenuItem>
                    <MenuItem value="CPL">CPL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="KPI Cost"
                  name="kpi_cost"
                  value={formData.kpi_cost}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="source">Source:</InputLabel>
                <Select
                  labelId="source"
                  name="source"
                  value={formData.source}
                  onChange={handleChange}
                >
                  <MenuItem value="Meta">Meta</MenuItem>
                  <MenuItem value="tiktok">TikTok</MenuItem>
                </Select>
              </FormControl>
            </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="template">Template:</InputLabel>
                  <Select
                    labelId="template"
                    name="template"
                    value={formData.template}
                    onChange={handleChange}
                  >
                    <MenuItem value="click">Click</MenuItem>
                    <MenuItem value="impression">Impression</MenuItem>
                    <MenuItem value="lead">Lead</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="report_type">Report Type:</InputLabel>
                  <Select
                    labelId="report_type"
                    name="report_type"
                    value={formData.report_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="1,0">External & Internal</MenuItem>
                    <MenuItem value="1">Only External</MenuItem>
                    <MenuItem value="0">Only Internal</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Output Filename"
                  name="output_filename"
                  value={formData.output_filename}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4} // or use rows={4} if you want a fixed number of rows
                label="Prompt"
                name="prompt"
                value={formData.prompt}
                onChange={handleChange}
              />
            </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status">Status:</InputLabel>
                  <Select
                    labelId="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Not Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#0AF17D", color: "white" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
