import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Grid,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";

export default function Campaigns() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchExpiry, setSearchExpiry] = useState("null");
  const [searchStatus, setSearchStatus] = useState("null");
  const [rows, setRows] = useState([]);
  const [fileToUpload, setFileToUpload] = useState("null");
  const [campaignFileToUpload, setCampaignFileToUpload] = useState("null");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadTarget, setUploadTarget] = useState("");
  const {
    isLoggedIn,
    partnerCode,
    token,
    apiUrl,
    userType,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();

  const toaster = useToast();

  window.redirectOffers = async (vat) => {
    navigate(`/offers/${vat}`);
  };

  window.redirectOrders = async (vat) => {
    navigate(`/orders/${vat}`);
  };

  const fetchData = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const _searchInput = searchInput === "" ? "null" : searchInput;
      const _searchStatus = searchStatus === "" ? "null" : searchStatus;
      ///const url =
      // partnerCode !== "SEDE001333" && partnerCode != 'SEDE002333'
      // ? `/exclusives/partner_code/${partnerCode}`
      //: `/exclusives/partners/${_searchInput}/${currentSearchExpiry}/${searchStatus}`;

      /*const url = !partnerCode.includes("SEDE")
      ? `/exclusives/partner_code/${partnerCode}`
      : `/exclusives/partners/${_searchInput}/${currentSearchExpiry}/${searchStatus}`

      if(partnerCode.includes("SEDE")) {
        setTarget("Partners Rete Commerciale")
      }*/
      const url = `/campaigns/${_searchInput}/${_searchStatus}`;
      const response = await API.get(url);
      unsetLoader();
      setRows(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  useEffect(() => {
    console.log("HEEEEEEEEEEEEEEEEEEERE");
    fetchData();
  }, [searchInput, searchExpiry, searchStatus, isLoggedIn, partnerCode]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      editable: false,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
      editable: false,
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      editable: false,
    },
    {
      field: "budget",
      headerName: "Budget",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return `${Number(params.value).toLocaleString()} €`;
      },
    },
    {
      field: "kpi",
      headerName: "KPI",
      flex: 1,
      editable: false,
    },
    {
      field: "kpi_cost",
      headerName: "KPI Cost",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        return `${Number(params.value).toLocaleString()} €`;
      },
    },
    {
      field: "template",
      headerName: "Template",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div>
          <Link to={`/exclusives/edit/${params.row._id}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
            >
              Modifica
            </Button>
          </Link>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleNotes(params.row._id)}
            sx={{ lineHeight: "0px", height: "31px" }}
          >
            Note1
          </Button>
        </div>
      ),
    },
  ];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSearchStatus(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileToUpload(file);
    }
    //alert(file)
  };

  const handleUpload = (id, name) => {
    setIsModalOpen(true);
    setUploadTarget(`for ${name}`);
    setCampaignFileToUpload(id);
  };

  const handleUploadSave = async () => {
    if (!fileToUpload) return;

    const formData = new FormData();
    formData.append("file", fileToUpload);
    //formData.append('campaign', campaignFileToUpload)
    setLoader();
    try {
      const url = `upload/${campaignFileToUpload}`;
      const response = await API.post(url, formData);
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" },
        );
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" },
        );
      }
      setIsModalOpen(false);
    } catch (error) {
      unsetLoader();
      toaster(
        {
          text: `${error}`,
        },
        { type: "error" },
      );
    }
  };

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
            {userType === "admin" ? (
              <>
                <Link to={`/campaigns/edit/${params.row._id}`}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      marginRight: "4px",
                      lineHeight: "0px",
                      height: "31px",
                    }}
                    style={{
                      marginRight: "10px",
                      backgroundColor: "#0AF17D",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Edit
                  </Button>
                </Link>
                {params.row.status === 1 ? (
                  <>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        marginRight: "4px",
                        lineHeight: "0px",
                        height: "31px",
                      }}
                      onClick={() =>
                        handleUpload(params.row._id, params.row.name)
                      }
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#0AF17D",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Upload
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      };
    }
    return column;
  });
  let index = 0;
  return (
    <div>
      <Box>
        <h1>Campaigns</h1>
        {userType === "admin" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <TextField
              size="small"
              label="Search"
              type="search"
              variant="outlined"
              style={{ marginRight: "10px", marginBottom: "10px" }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            &nbsp;
            <Select
              size="small"
              value={searchStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="null">All Status</MenuItem>
              <MenuItem value="1">Active&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <MenuItem value="0">Not Active&nbsp;&nbsp;&nbsp;</MenuItem>
            </Select>
          </div>
        )}
        <Box>
          <DataGrid
            className="dataGrid"
            isCellEditable={() => false}
            rows={rows}
            columns={columnsWithActions} // Use the modified columns array
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0
                ? "evenRow"
                : "oddRow";
              //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            }}
          />
        </Box>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
              alignItems: "center",
            }}
          >
            <h2>Upload {uploadTarget}</h2>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "block", marginBottom: "20px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadSave}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#0AF17D",
                  color: "white",
                }}
              >
                Save
              </Button>
            </form>
          </Box>
        </Modal>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
