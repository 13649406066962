import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Grid,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";

export default function Reports() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchCampaign, setSearchCampaign] = useState("null");
  const [searchStartDate, setSearchStartDate] = useState("null");
  const [searchEndDate, setSearchEndDate] = useState("null");
  const [searchStep, setSearchStep] = useState("null");
  const [rows, setRows] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState("");
  const {
    isLoggedIn,
    partnerCode,
    token,
    apiUrl,
    userType,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();

  const toaster = useToast();



  const fetchData = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const _searchInput = searchInput === "" ? "null" : searchInput;
      const url = `/reports/${_searchInput}`;
      console.log(url);
      const response = await API.get(url);
      unsetLoader();
      setRows(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign",
      flex:1,
      editable: false,
    },
    {
      field: "report_type",
      headerName: "Report Type",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        const value = params.value?.toString().split(",") || [];
        const labels = {
          "1": "External",
          "0": "Internal",
        };
        return value.map((v) => labels[v.trim()] || v).join(", ");
      },
    },    
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const [year, month, day] = params.value.split("-");
        return `${day}/${month}/${year}`;
      },
    },    
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const { status, output_file_external, output_file_internal } = params.row;
    
        return (
          <div>
            {status === 1 && output_file_external && (
              <a href={`https://report-robot-api.rankit.it/${output_file_external.replace('/home/djara/report-robot/','')}`} target="_blank" rel="noopener noreferrer">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    marginRight: "4px",
                    lineHeight: "0px",
                    height: "31px",
                  }}
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#0AF17D",
                    color: "white",
                    border: "none",
                  }}
                >
                  Download External
                </Button>
              </a>
            )}
            {status === 1 && output_file_internal && (
<a href={`https://report-robot-api.rankit.it/${output_file_internal.replace('/home/djara/report-robot/','')}`} target="_blank" rel="noopener noreferrer">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    marginRight: "4px",
                    lineHeight: "0px",
                    height: "31px",
                  }}
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#0AF17D",
                    color: "white",
                    border: "none",
                  }}
                >
                  Download Internal
                </Button>
              </a>
            )}
          </div>
        );
      },
    }
    
    
  ];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };



  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isNotes == true) {
      setIsNotes(false);
    }
    if (isPartners == true) {
      setIsPartners(false);
    }
    if (isCreateNote == true) {
      setIsCreateNote(false);
    }
    if (isExtension == true) {
      setIsExtension(false);
      setIsExtensionOffers(false);
      setIsExtensionOrders(false);
    }
    setNoteValue(undefined);
    setExclusiveId(undefined);
    setExclusivePartnerCode(undefined);
  };

  if (!isLoggedIn) return <Signin />;


  let index = 0;
  return (
    <div>
      <Box>
        <h1>Reports </h1>
        {userType === "admin" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <TextField
              size="small"
              label="Search"
              type="search"
              variant="outlined"
              style={{ marginBottom: "10px" }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={fetchData}
              style={{ backgroundColor: "#0AF17D", color: "white" }}
            >
              Reload
            </Button>
          </div>
        )}
        <Box>
          <DataGrid
            className="dataGrid"
            isCellEditable={() => false}
            rows={rows}
            columns={columns} // Use the modified columns array
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0
                ? "evenRow"
                : "oddRow";
              //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            }}
          />
        </Box>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
